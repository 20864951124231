export const environment = {
  production: false,
  hmr: false,
  showConsoleLog: true,
  apiUrl: 'https://ecmsapi.centurycodes.ng/api',
  courtTitle: 'HIGH COURT OF GOMBE',
  stateTitle: 'GOMBE STATE OF NIGERIA',
  stateTitleSentence: 'Gombe State Of Nigeria',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.centurycodes.ng/api',
  BASE_URL_II: 'https://ecmsapi.centurycodes.ng/api',
  API_URL: '/',
  currentState: 'Gombe State',
  currentDivision: 'Gombe Judicial Division',
  PORTAL_URL: 'https://ecmsportal.centurycodes.ng',
  court_bg_path: 'assets/img/gombe-ecms.png',
  court_logo_path: 'assets/img/gombe-ecms-2.png',
  paystack_public_key: 'pk_test_0e8e87eb3fdf39c788648eefeb54063a3c293a3d',
  paystack_split_code: 'SPL_K0t6TE0Fmm',
  remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  remitta_mode: 'test',
  interswitch_mechant_code: 'VNA',
  //interswitch_mechant_code: 'MX128354',
  interswitch_data_def: 'pk_test_0e8e87eb3fdf39c788648eefeb54063a3c293a3d',
  interswitch_pay_item: '103',
  interswitch_mode: 'TEST',
  currentCourtInfoEmail: "info@gm.gov.ng"
};
